import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";

import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";

import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DialogReadOnlyComponent } from "./dialog-readOnly/dialog-readOnly.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { DialogIncidenciaComponent } from "app/components/dialog-incidencia/dialog-incidencia.component";

@NgModule({
	declarations: [ToolbarComponent, DialogReadOnlyComponent, DialogIncidenciaComponent],
	imports: [
		RouterModule,
		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		FontAwesomeModule,
		InfiniteScrollModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,

		FuseSharedModule,
		FuseSearchBarModule,
		FuseShortcutsModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
