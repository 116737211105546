import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseConfigService } from "@fuse/services/config.service";

// import { RainbowService } from 'app/services/rainbow/rainbow.service';

@Component({
	selector: "navbar-vertical-style-1",
	templateUrl: "./style-1.component.html",
	styleUrls: ["./style-1.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;

	// Private
	private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseNavigationService} _fuseNavigationService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {Router} _router
	 * @param {RainbowService} _rbwService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _router: Router,
	) {
		// private _rbwService: RainbowService
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	// Directive
	@ViewChild(FusePerfectScrollbarDirective, { static: true })
	set directive(theDirective: FusePerfectScrollbarDirective) {
		if (!theDirective) {
			return;
		}

		this._fusePerfectScrollbar = theDirective;

		// Update the scrollbar on collapsable item toggle
		this._fuseNavigationService.onItemCollapseToggled.pipe(delay(500), takeUntil(this._unsubscribeAll)).subscribe(() => {
			this._fusePerfectScrollbar.update();
		});

		// Scroll to the active item position
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				take(1),
			)
			.subscribe(() => {
				setTimeout(() => {
					this._fusePerfectScrollbar.scrollToElement("navbar .nav-link.active", -120);
				});
			});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll),
			)
			.subscribe(() => {
				if (this._fuseSidebarService.getSidebar("navbar")) {
					this._fuseSidebarService.getSidebar("navbar").close();
				}
			});

		// Subscribe to the config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.fuseConfig = config;
		});

		// Get current navigation
		this._fuseNavigationService.onNavigationChanged
			.pipe(
				filter((value) => value !== null),
				takeUntil(this._unsubscribeAll),
			)
			.subscribe(() => {
				this.navigation = this._fuseNavigationService.getCurrentNavigation();
			});

		// this._rbwService.onContadorMensajesNoLeidos
		//     .pipe(takeUntil(this._unsubscribeAll))
		//     .subscribe(data => {
		//         console.log("mensajes no leidos", data.counter);
		//         const counter = data.counter;
		//         this._fuseNavigationService.updateNavigationItem('chat',
		//             {
		//                 'title': 'Chat',
		//                 'badge': {
		//                     'title': counter,
		//                     'bg'   : '#F44336',
		//                     'fg'   : '#FFFFFF'
		//                 }
		//             }
		//         );
		//     });
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void {
		this._fuseSidebarService.getSidebar("navbar").toggleOpen();
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void {
		this._fuseSidebarService.getSidebar("navbar").toggleFold();
	}
}
