import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { UserService } from "../user/user.service";

@Injectable({
	providedIn: "root",
})
export class ValidateHashChangePasswordGuardService {
	constructor(
		public router: Router,
		public auth: AuthService,
		private userSvc: UserService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		// console.log("validateHashChangePassword canActivate");
		const hash = route.params.hash;
		const date = route.params.date;
		const userId = parseInt(route.params.idUser);

		// return true;
		return this.auth.validateHashPassword(userId, date, hash);
	}
}
