import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class AccionesFormativasGuard {
	projectId: number;
	project: any;

	constructor(public router: Router, private projectSvc: ProjectService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		try {
			this.projectId = parseInt(this.projectSvc.getProjectId());
			this.projectSvc.getProjectConfig().subscribe((data) => {
				this.project = data;
			});

			if (!(this.project.entryPoint.formaciones_enabled == 1)) {
				this.router.navigate(["inicio"]);
				return false;
			} else {
				return true;
			}
		} catch {
			this.router.navigate(["inicio"]);
			return false;
		}
	}
}
