import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import moment from "moment";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ImagesService } from "../../../services/helper/images.service";
import { PostsService } from "../../../services/posts/posts.service";
import { ProjectService } from "../../../services/project/project.service";

import { Comment, Like } from "../../../shared/models/comun.interface";
import { Post } from "../../../shared/models/posts.interface";
import { User } from "../../../shared/models/user.interface";

@Component({
	selector: "item-post",
	templateUrl: "./item-post.component.html",
	styleUrls: ["./item-post.component.scss"],
})
export class ItemPostComponent implements OnInit {
	@Input("id") idPost: number;
	@Input("datos") post: Post;
	@Input("user") user: User;
	@Input("fromInside") fromInside: boolean = false;
	@Input("canEdit") canEdit: any;
	canEditComments;
	@Input("canSee") canSee: any;

	canSeeComments;

	htmlContent: SafeHtml;
	form: UntypedFormGroup;
	showFormComent: boolean;

	projectId: number;
	likes: Array<Like>;
	tooltipLikes: string;
	comments: Array<Comment>;
	tit: any;
	isRankingLink = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private sanitizer: DomSanitizer,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private postsSvc: PostsService,
		public dialog: MatDialog
	) {
		this.comments = [];
		this.likes = [];
		this.tooltipLikes = "";
	}

	ngOnInit(): void {
		this.canEditComments = this.canEdit;
		this.canSeeComments = this.canSee;
		this.form = this._formBuilder.group({
			comentario: [""],
		});

		this.showFormComent = false;

		this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.post.contenido);
		this.projectId = parseInt(this.projectSvc.getProjectId());
		moment.locale(this.user.language);
		this.loadLikesComments();
		this.traducirTitulo();
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const postDate = moment(timestamp);
		const diffDias = hoy.diff(postDate, "days");

		let momentDate = postDate.fromNow();
		if (diffDias > 21) {
			momentDate = postDate.format("DD MMMM YYYY");
		}

		return momentDate;
	}
	momentDateFormat(timestamp: number) {
		// console.log("fecha", moment(timestamp));
		return moment(timestamp).format("DD/MM/YYYY HH:mm");
	}

	toogleLikePost() {
		this.postsSvc.toogleLike(this.post.id).subscribe(
			(data) => {
				if (this.post.isLiked) {
					this.post.numLikes--;
					this.post.isLiked = false;
				} else {
					this.post.numLikes++;
					this.post.isLiked = true;
				}
				this.loadLikes();
			},
			(error) => {
				this.snackBar.open(error, "X", {
					duration: 3000,
				});
			}
		);
	}

	toogleFormComment(idPost: number) {
		if (this.showFormComent) {
			this.showFormComent = false;
		} else {
			this.showFormComent = true;
		}
	}

	loadLikesComments(): void {
		if (this.post.numComments > 0) {
			this.loadComments();
		}
		if (this.post.numLikes > 0) {
			this.loadLikes();
		}
	}

	loadLikes(): void {
		this.postsSvc.postLikes(this.post.id).subscribe((data: any) => {
			this.likes = data.elements;
			this.tooltipLikes = "";
			for (const like of this.likes) {
				if (this.tooltipLikes !== "") {
					this.tooltipLikes += "\n";
				}
				this.tooltipLikes += like.autor.name;
			}
		});
	}

	loadComments(): void {
		this.postsSvc.postComments(this.post.id).subscribe((data: any) => {
			this.comments = data.elements;
		});
	}

	openDialogVerPublicacion() {
		if (!this.fromInside) {
			const dialogRef = this.dialog.open(ItemPostComponent, {
				data: {},
				panelClass: "panel-feedbalia",
				// maxWidth: '70vw',
				disableClose: false,
				autoFocus: false,
				height: "95%",
			});
			dialogRef.componentInstance.idPost = this.idPost;
			dialogRef.componentInstance.user = this.user;
			dialogRef.componentInstance.post = this.post;
			dialogRef.componentInstance.fromInside = true;
			dialogRef.updatePosition({ top: "4rem" });
		}
	}

	traducirTitulo() {
		if (this.post["title_prefix"].includes("${ranking.month}")) {
			this.isRankingLink = true;
			var expresion = /\/ranking\/+([0-9])+\/+([0-9])?([0-9])/.exec(this.post["title_prefix"]);
			var year = expresion[0].split("/")[2];
			var month = this.convertirMes(expresion[0].split("/")[3]);
			this.tit = this.post["title_prefix"].replace("${ranking.month}", "Ranking de " + year + " " + month);
		} else {
			this.tit = this.post["titulo"];
		}
		return this.tit;
	}

	convertirMes(numeroMes) {
		var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		if (numeroMes >= 1 && numeroMes <= 12) {
			return meses[numeroMes - 1];
		}
	}
}
