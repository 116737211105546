<mat-toolbar>
	<div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex class="footer px-16">
		@if (project) {
			@if (project.id != 472) {
				<div fxLayout="row">
					<a href="http://feedbalia.com/" target="_blank" class="font-size-14 mr-16">feedbalia.com</a>
					<a href="https://twitter.com/feedbalia_" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16 fa fa-twitter-square"></mat-icon> -->
						<fa-icon [icon]="faTwitterSquare"></fa-icon>
					</a>
					<a href="http://www.linkedin.com/company/ifeedback365" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16">add_a_photo</mat-icon> -->
						<fa-icon [icon]="faLinkedin"></fa-icon>
					</a>
					<a href="https://www.youtube.com/channel/UCUqN0pLDXG0KzPmCfJpnlhQ" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16">account_balance</mat-icon> -->
						<fa-icon [icon]="faYoutubeSquare"></fa-icon>
					</a>
				</div>
			} @else {
				<div fxLayout="row">
					<a href="http://enalta.es/" target="_blank" class="font-size-14 mr-16">enalta.es</a>
					<a href="https://x.com/enalta__" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16 fa fa-twitter-square"></mat-icon> -->
						<fa-icon [icon]="faTwitterSquare"></fa-icon>
					</a>
					<a href="http://www.linkedin.com/company/enalta-serviciosfunerariosconmemorativos" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16">add_a_photo</mat-icon> -->
						<fa-icon [icon]="faLinkedin"></fa-icon>
					</a>
					<a href="https://www.youtube.com/@enalta_" target="_blank" class="mr-16">
						<!-- <mat-icon class="s-16">account_balance</mat-icon> -->
						<fa-icon [icon]="faYoutubeSquare"></fa-icon>
					</a>
				</div>
			}
		}

		<ng-container *ngIf="project">
			<div fxLayout="row" fxLayoutAlign="center center">
				<mat-icon class="s-16">copyright</mat-icon>
				<span class="font-size-14 mr-8 ml-4">{{ agno }} feedbalia</span>
				<span *ngIf="this.isLegalTermsEnabled && project.id != 10">|</span>
				<!-- <a href="https://emprepolis.feedbalia.com/legalTerms" target="_blank" class="font-size-14 ml-8 aviso-legal">{{ 'legalTerms.linkText' | translate }}</a> -->
				<a *ngIf="this.isLegalTermsEnabled && project.id != 10 && project.id != 4" class="font-size-14 ml-8 aviso-legal" routerLink="/legal-terms">{{
					"legalTerms.linkText" | translate
				}}</a>
			</div>
		</ng-container>
		<!-- <a href="https://1.envato.market/c/1257954/275988/4415?u=https%3A%2F%2Fthemeforest.net%2Fitem%2Ffuse-angularjs-material-design-admin-template%2F12931855"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase FUSE (Angular 8+)</span>
        </a>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div> -->
	</div>
</mat-toolbar>
