<div fxLayout="row" class="p-16 item-badge is-timeline">
	<div class="pr-5" fxLayout="row" fxLayoutAlign="center flex-start" *ngIf="project && project.entryPoint.showBadgeImageTimeline">
		<img
			*ngIf="badge.competency.typeCompetency === 0"
			class="img-competency-timeline"
			src="{{ imagesSvc.getUrlImageCompetency(badge.competency) }}"
		/>
		<img *ngIf="badge.competency.typeCompetency !== 0" class="img-competency-timeline" src="{{ imagesSvc.getUrlImageDefaultMission() }}" />
	</div>

	<div class="person-avatar pr-16" fxLayout="row" fxLayoutAlign="center flex-start">
		<img
			*ngIf="project && project.entryPoint.showTimelineGrantor"
			class="img-circle"
			[src]="getImagePerson(badge.userDeliver.username)"
			[title]="badge.userDeliver.username"
		/>
		<img
			*ngIf="project.id == 10"
			class="img-circle badge-image"
			[src]="getBadgeImage(this.badge.competency.id)"
			[title]="badge.userDeliver.username"
		/>
		<img
			*ngIf="project && !project.entryPoint.showTimelineGrantor && badge.userReceive"
			class="img-circle"
			[src]="getImagePerson(badge.userReceive.username)"
			[title]="badge.userReceive.username"
		/>
		<!--div *ngIf="project && (!project.entryPoint.showTimelineGrantor) && badge.grantees.length>0 && !badge.userReceive" class="img-circle" style="width: 50px; text-align: center;">
            <mat-icon>G</mat-icon>
        </div -->
		<img
			*ngIf="project && !project.entryPoint.showTimelineGrantor && badge.grantees.length > 0 && !badge.userReceive"
			[src]="getImageGroup()"
			class="img-circle"
		/>
	</div>

	<div fxFlex="1 1 0">
		<div fxLayout="row" fxLayoutAlign="space-between flex-start" class="mb-12">
			<p class="m-0">
				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 1">
					{{ "home.badges.from" | translate }}
					<a
						*ngIf="badge.userDeliver && user"
						[routerLink]="badge.userDeliver.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userDeliver.username]"
						style="cursor: pointer"
						>{{ badge.userDeliver.name }}</a
					>
					{{ "home.badges.to" | translate }}
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 2">
					<a
						*ngIf="badge.userDeliver && user"
						[routerLink]="badge.userDeliver.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userDeliver.username]"
						style="cursor: pointer"
						>{{ badge.userDeliver.name }}</a
					>
					<span *ngIf="badge.private === 0">{{ "general.ha_reconocido_a" | translate }}</span>
					<span *ngIf="badge.private === 1">{{ "general.feedbackdemejora_a" | translate }}</span>
				</ng-container>

				<ng-container *ngIf="badge.userReceive && user"
					><a [routerLink]="badge.userReceive.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userReceive.username]" style="cursor: pointer">{{
						badge.userReceive.name
					}}</a>
				</ng-container>

				<ng-container *ngIf="badge.grantees && badge.grantees.length > 0 && badge.grantees.length <= 10 && !badge.userReceive">
					<ng-container *ngFor="let grantee of badge.grantees; let i = index">
						<span *ngIf="i > 0 && i < badge.grantees.length - 1">, </span>
						<span *ngIf="i == badge.grantees.length - 1"> y </span>
						<a [routerLink]="grantee.id === user.id ? ['/perfil'] : ['/perfil/@' + grantee.username]" style="cursor: pointer">{{ grantee.name }}</a>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="badge.grantees && badge.grantees.length > 0 && badge.grantees.length > 10 && !badge.userReceive">
					<b>{{ badge.grantees.length }} {{ "timeline.users" | translate }}</b>
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 1">
					{{ "home.badges.for" | translate }} {{ badge.competency.name }}.
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 2">
					{{ "general.in" | translate }} {{ textTipoCompetencia(badge.competency.typeCompetency) }}.
				</ng-container>

				<ng-container *ngIf="project && project.enabled_subject && badge.subject != null && badge.subject != ''">
					<p>{{ "general.for" | translate }} {{ badge.subject }}</p>
				</ng-container>
			</p>
			<p class="badge-date m-0 ml-8" [title]="momentDateFormat(badge.fecha)">
				{{ momentDate(badge.fecha) }}
				<ng-container *ngIf="project.enabledModPointsBadge">
					<br />
					{{ badge.points }} {{ "general.coins" | translate }}
				</ng-container>
			</p>
		</div>
		<!-- Feedback mejora -->
		<div *ngIf="badge.private" class="private-badge">
			<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
				<path
					fill="currentColor"
					d="M13.13 22.19L11.5 18.36C13.07 17.78 14.54 17 15.9 16.09L13.13 22.19M5.64 12.5L1.81 10.87L7.91 8.1C7 9.46 6.22 10.93 5.64 12.5M21.61 2.39C21.61 2.39 16.66 .269 11 5.93C8.81 8.12 7.5 10.53 6.65 12.64C6.37 13.39 6.56 14.21 7.11 14.77L9.24 16.89C9.79 17.45 10.61 17.63 11.36 17.35C13.5 16.53 15.88 15.19 18.07 13C23.73 7.34 21.61 2.39 21.61 2.39M14.54 9.46C13.76 8.68 13.76 7.41 14.54 6.63S16.59 5.85 17.37 6.63C18.14 7.41 18.15 8.68 17.37 9.46C16.59 10.24 15.32 10.24 14.54 9.46M8.88 16.53L7.47 15.12L8.88 16.53M6.24 22L9.88 18.36C9.54 18.27 9.21 18.12 8.91 17.91L4.83 22H6.24M2 22H3.41L8.18 17.24L6.76 15.83L2 20.59V22M2 19.17L6.09 15.09C5.88 14.79 5.73 14.47 5.64 14.12L2 17.76V19.17Z"
				/>
			</svg>
			<p class="m-0" style="color: darkgreen">
				<strong>Feedback de mejora</strong>
			</p>
		</div>
		<div class="badge-motivos">
			<p *ngFor="let motivo of badge['competencyIndicator']"><b>o</b> {{ motivo.name }}</p>
		</div>
		<div class="p-16 mb-8 badge-comment">
			<p class="m-0">{{ badge.comentario }}</p>
		</div>

		@if(canSeeComments){
		<div fxLayout="row" fxLayoutAlign="space-between center">
			<p class="text-muted m-0">
				<span
					*ngIf="project && project.enabledBadgeLike"
					style="cursor: pointer"
					[matTooltip]="tooltipLikes"
					[matTooltipClass]="'tooltip-lines'"
					showDelay="500"
					>{{ badge.numLikes }} {{ "addOn.comment.likes" | translate }}</span
				>
				·
				<a *ngIf="project && project.enabledBadgeComment && badge" style="cursor: pointer" class="text-muted" (click)="toogleFormComment(badge.id)"
					>{{ badge.numComments }} {{ "post.comments" | translate }}</a
				>
			</p>
			<div fxLayout="row" fxLayoutAlign="center center" class="badge-actions">
				@if(canEditComments){
				<div *ngIf="project && project.enabledBadgeLike && badge.userDeliver.id !== user.id">
					@if(projectId!=472){
					<button *ngIf="!badge.isLiked" mat-stroked-button color="primary" class="btn-like" (click)="toogleLikeBadge()">
						{{ "addOn.comment.like.btn" | translate }}
					</button>
					<button *ngIf="badge.isLiked" mat-raised-button color="primary" class="btn-like active" (click)="toogleLikeBadge()">
						{{ "addOn.comment.like.btn" | translate }}
					</button>
					}@else {
					<button mat-stroked-button class="botonFunespana" (click)="toogleLikeBadge()">{{ !badge.isLiked ? "Like" : "Deshacer Like" }}</button>

					}
				</div>
				}
				<button
					*ngIf="project && project.enabledBadgeComment"
					mat-icon-button
					color="primary"
					class="btn-comments"
					color="primary"
					(click)="toogleFormComment(badge.id)"
				>
					<mat-icon>chat_bubble</mat-icon>
				</button>
			</div>
		</div>

		<fb-comentar *ngIf="showFormComent && badge" [id_badge]="badge.id" [user]="user" [canEdit]="canEditComments"></fb-comentar>
		}
	</div>
</div>
