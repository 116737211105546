import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, catchError, map } from "rxjs/operators";
import { BadgesService } from "./badges.service";
import { UserService } from "../user/user.service";

@Injectable({
	providedIn: "root",
})
export class BadgeModerationGuard {
	constructor(
		private router: Router,
		private badgeSvc: BadgesService,
		private userSvc: UserService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const medallaId = route.paramMap.get("idBadge");

		return this.userSvc.getUser().pipe(
			switchMap((user) => {
				return this.badgeSvc.getBadge(Number(medallaId)).pipe(
					map((itemBadge) => {
						if (user.id !== itemBadge.moderator_id) {
							return this.router.parseUrl("/inicio");
						} else {
							return true;
						}
					}),
					catchError(() => {
						return of(this.router.parseUrl("/inicio")); // Devolver la UrlTree dentro de un Observable
					}),
				);
			}),
			catchError(() => {
				return of(this.router.parseUrl("/inicio")); // Devolver la UrlTree dentro de un Observable
			}),
		);
	}
}
