<div fxLayout="column" class="fuse-card badge-mes card-border-top">
	<mat-icon *ngIf="tipo !== 'MONEDAS'" class="icon-badge secundary-text">loyalty</mat-icon>
	<fa-icon *ngIf="tipo === 'MONEDAS'" class="icon-badge secundary-text" icon="coins"></fa-icon>
	<div fxLayout="column" fxLayoutAlign="center center" class="p-16">
		<h5 *ngIf="tipo === 'RECIBIDO' || tipo === 'RECIBIDOMEJORA'" class="secundary-text m-0">{{ "index.statistics.obtained" | translate }}</h5>
		<h5 *ngIf="tipo === 'ENTREGADO' || tipo === 'ENTREGADOMEJORA'" class="secundary-text m-0">{{ "index.statistics.given" | translate }}</h5>

		<h5 *ngIf="tipo === 'MONEDAS'" class="secundary-text m-0">{{ "index.statistics.coins.have" | translate }}</h5>
		<h3 class="font-size-36 mt-4 mb-4">{{ numBadges }}</h3>
		<h5 *ngIf="tipo === 'RECIBIDO' || tipo === 'ENTREGADO'" class="secundary-text m-0">{{ "index.statistics.badges.month" | translate }}</h5>
		<h5 *ngIf="tipo === 'RECIBIDOMEJORA' || tipo === 'ENTREGADOMEJORA'" class="secundary-text m-0">
			{{ "index.statistics.badgesmejoras.month" | translate }}
		</h5>

		<h5 *ngIf="tipo === 'MONEDAS'" class="secundary-text m-0">{{ "general.coins" | translate }}</h5>
	</div>
</div>
