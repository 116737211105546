import { Component, OnInit } from "@angular/core";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { BadgesService } from "app/services/badges/badges.service";
import { UserService } from "app/services/user/user.service";

import { Badge } from "app/shared/models/badge.interface";
import { ProfileUser, User } from "app/shared/models/user.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "badge-bar",
	templateUrl: "./badge-bar.component.html",
	styleUrls: ["./badge-bar.component.scss"],
})
export class BadgeBarComponent implements OnInit {
	fuseConfig: any;
	user: User;
	profileUser: ProfileUser;
	pageTimeline: number;
	badgesTimeline: Array<Badge>;
	hasMoreTimeline: boolean;

	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private badgesSvc: BadgesService,
		private userSvc: UserService,
	) {
		this.pageTimeline = 0;
		this.badgesTimeline = [];

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.fuseConfig = config;
		});

		this.userSvc.getUser().subscribe((data: User) => {
			this.user = data;
		});

		this.loadTimeline();
	}

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void {
		this._fuseSidebarService.getSidebar("badgebar").toggleOpen();
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void {
		this._fuseSidebarService.getSidebar("badgebar").toggleFold();
	}

	loadTimeline(): void {
		this.badgesSvc.timeline(this.pageTimeline, 20).subscribe((data: any) => {
			this.processDataTimeline(data);
		});
	}

	onSrollTimeline() {
		if (this.hasMoreTimeline) {
			this.pageTimeline++;
			this.loadTimeline();
		}
	}

	private processDataTimeline(data: any) {
		this.hasMoreTimeline = data.hasMore;

		for (let itemBadge of data.elements) {
			this.badgesTimeline.push(<Badge>itemBadge);
		}
	}
}
