import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { FuseSharedModule } from "@fuse/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AuthGuardService } from "../../services/auth/auth-guard.service";
import { MustChangePasswordGuardService } from "../../services/auth/must-change-password-guard.service";

import { InicioComponent } from "./inicio.component";
import { BadgesMesComponent } from "./badges-mes/badges-mes.component";
import { ItemBadgeComponent } from "./item-badge/item-badge.component";
import { ItemPostComponent } from "./item-post/item-post.component";
import { DialogClimaComponent } from "./dialog-clima/dialog-clima.component";

import { SharedComponentsModule } from "../../components/shared-components.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatDividerModule } from "@angular/material/divider";
const routes = [
	{
		path: "inicio",
		component: InicioComponent,
		canActivate: [AuthGuardService, MustChangePasswordGuardService],
	},
];

@NgModule({
	declarations: [InicioComponent, BadgesMesComponent, ItemBadgeComponent, ItemPostComponent, DialogClimaComponent],
	imports: [
		RouterModule.forChild(routes),

		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		MatListModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatTabsModule,
		MatChipsModule,
		MatProgressSpinnerModule,

		FuseSharedModule,
		FontAwesomeModule,
		SharedComponentsModule,
		InfiniteScrollModule,
		MatDividerModule,
	],
	exports: [InicioComponent],
})
export class InicioModule {}
