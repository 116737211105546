import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "app/services/auth/auth.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";

import { Project } from "app/shared/models/project.interface";
import { Persona, User } from "app/shared/models/user.interface";

@Component({
	selector: "dialog-readOnly",
	templateUrl: "./dialog-readOnly.component.html",
	styleUrls: ["./dialog-readOnly.component.scss"],
})
export class DialogReadOnlyComponent implements OnInit {
	persons: Array<any>;
	hasMorePersons: boolean;
	currentPagePersons: number;

	filterPersonValue: string;

	userId: number;
	projectId: number;
	project: Project;
	personSelected: any;
	personsSelected: Array<any>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { badgeReturn: string; personId: number },
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private userSvc: UserService,
		private personSvc: PersonService,
		private authSvc: AuthService,
	) {
		this.filterPersonValue = "";

		this.persons = [];
		this.hasMorePersons = false;

		this.personSelected = null;
		this.personsSelected = [];
	}

	ngOnInit(): void {
		this.projectId = parseInt(this.projectSvc.getProjectId());
		this.userSvc.getUser().subscribe(
			(data: User) => {
				this.userId = data.id;
			},
			(err) => {
				this.showSnack(err);
			},
		);
		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
			},
			(err) => {
				this.showSnack(err);
			},
		);
	}

	filterPersons(event: Event) {
		if (this.filterPersonValue.length > 2) {
			this.currentPagePersons = 0;
			this.persons = [];
			this.getPersons();
		} else {
			this.currentPagePersons = 0;
			this.persons = [];
		}
	}

	getPersons() {
		this.personSvc.getPersons(this.filterPersonValue, this.currentPagePersons, 5).subscribe(
			(personsData) => {
				this.persons = [];
				let aux = this.persons.concat(personsData.elements);
				const dataArr = new Set(aux);
				this.persons = [...dataArr];
			},
			(err) => {
				this.showSnack(err);
			},
		);
	}

	getPerson(personId) {
		this.personSvc.getPerson(personId).subscribe((data) => {
			this.selectPerson(data);
		});
	}

	getImagePerson(username: string) {
		return this.imagesSvc.getImageThumb(username);
	}

	getImagePersonGrande(username: string) {
		return this.imagesSvc.getImagePerson(username, this.projectId);
	}

	selectPerson(user: Persona) {
		this.personSelected = user;
		this.userSvc.loginReadOnlyMode(user.id.toString()).subscribe((data) => {
			this.authSvc.saveToken(data, true);
			location.reload();
		});
	}

	clearPerson() {
		this.personSelected = null;
		this.filterPersonValue = "";
		this.persons = [];
	}

	showSnack(mensaje: string) {
		this.snackBar.open(mensaje, "X", {
			duration: 3000,
		});
	}
}
