import { Component } from "@angular/core";
import { faLinkedin, faTwitterSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { AnalyticsService } from "app/services/analytics/analytics.service";
import { ProjectService } from "app/services/project/project.service";
import { Project } from "app/shared/models/project.interface";
import moment from "moment";
@Component({
	selector: "footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
	faTwitterSquare = faTwitterSquare;
	faLinkedin = faLinkedin;
	faYoutubeSquare = faYoutubeSquare;
	project: Project;
	isLegalTermsEnabled: boolean;
	agno = moment().format("YYYY");

	/**
	 * Constructor
	 */
	constructor(
		private analyticsSvc: AnalyticsService,
		private projectSvc: ProjectService,
	) {}

	ngOnInit() {
		this.analyticsSvc.init();
		this.isLegalTermsEnabled = localStorage.getItem("isLegalTermsEnabled") === "true" ? true : false;

		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
				if (this.project.id == 9) {
					this.isLegalTermsEnabled = false;
				}
			},
			(err) => {},
		);
	}
}
