<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
	<!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
	<!-- TOOLBAR: Above -->
	<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
		<ng-container *ngTemplateOutlet="toolbar"></ng-container>
	</ng-container>
	<!-- / TOOLBAR: Above -->

	<div id="container-1" class="container">
		<!-- NAVBAR: Left -->
		<ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
			<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Left -->

		<div id="container-2" class="container">
			<!-- TOOLBAR: Below fixed -->
			<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
				<ng-container *ngTemplateOutlet="toolbar"></ng-container>
			</ng-container>
			<!-- / TOOLBAR: Below fixed -->

			<div
				id="container-3"
				class="container"
				fusePerfectScrollbar
				[fusePerfectScrollbarOptions]="{ suppressScrollX: true, updateOnRouteChange: true }"
			>
				<!-- TOOLBAR: Below static -->
				<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
					<ng-container *ngTemplateOutlet="toolbar"></ng-container>
				</ng-container>
				<!-- / TOOLBAR: Below static -->

				<!-- CONTENT -->
				<content></content>
				<!-- / CONTENT -->

				<!-- FOOTER: Below static -->
				<ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static'">
					<ng-container *ngTemplateOutlet="footer"></ng-container>
				</ng-container>
				<!-- / FOOTER: Below static -->
			</div>

			<!-- FOOTER: Below fixed -->
			<ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
				<ng-container *ngTemplateOutlet="footer"></ng-container>
			</ng-container>
			<!-- / FOOTER: Below fixed -->
		</div>

		<!-- NAVBAR: Right -->
		<ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
			<ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Right -->

		<!-- BADGEBAR: Right -->
		<ng-container *ngIf="renderBadgeBar">
			<ng-container *ngTemplateOutlet="rightBadgeBar"></ng-container>
		</ng-container>
		<!-- / BADGEBAR: Right -->
	</div>

	<!-- FOOTER: Above -->
	<ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</ng-container>
	<!-- FOOTER: Above -->
</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">
	<!-- PANEL CONTENT -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
	<quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
	<toolbar
		*ngIf="!fuseConfig.layout.toolbar.hidden"
		[ngClass]="
			fuseConfig.layout.toolbar.customBackgroundColor === true
				? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
				: fuseConfig.layout.toolbar.position
		"
	>
	</toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
	<footer
		*ngIf="!fuseConfig.layout.footer.hidden"
		[ngClass]="
			fuseConfig.layout.footer.customBackgroundColor === true
				? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background
				: fuseConfig.layout.footer.position
		"
	></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
	<fuse-sidebar
		name="navbar"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		lockedOpen="gt-md"
		*ngIf="!fuseConfig.layout.navbar.hidden"
	>
		<navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
	</fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
	<fuse-sidebar
		name="navbar"
		position="right"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		lockedOpen="gt-md"
		*ngIf="!fuseConfig.layout.navbar.hidden"
	>
		<navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
	</fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->

<!-- RIGHT BADGEBAR -->
<ng-template #rightBadgeBar>
	<fuse-sidebar
		name="badgebar"
		position="right"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		lockedOpen="gt-md"
		foldedWidth="40"
		*ngIf="!fuseConfig.layout.navbar.hidden"
	>
		<badge-bar [variant]="fuseConfig.layout.navbar.variant" class="badge-bar"></badge-bar>
	</fuse-sidebar>
</ng-template>
<!-- / RIGHT BADGEBAR -->
