import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProjectService } from "../project/project.service";
import { UserService } from "../user/user.service";

@Injectable()
export class CheckOnlyManu {
	projectId: number;
	project: any;

	constructor(
		public router: Router,
		private userSvc: UserService,
		private projectSvc: ProjectService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return new Promise<boolean>((resolve, reject) => {
			const pro = this.projectSvc.projectConfig;

			this.userSvc.getDataUser().subscribe(
				(data) => {
					if (data.email !== "m.sanchez@exxita.com") {
						this.router.navigate(["/inicio"]);
						resolve(false);
					} else {
						resolve(true);
					}
				},
				(error) => {
					this.router.navigate(["inicio"]);
					resolve(false);
				},
			);
		});
	}
}
