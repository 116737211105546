import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";

import { MatSnackBar } from "@angular/material/snack-bar";

import { BadgesService } from "../../../services/badges/badges.service";
import { ImagesService } from "../../../services/helper/images.service";
import { ProjectService } from "../../../services/project/project.service";
import { UserService } from "../../../services/user/user.service";

import { Project } from "app/shared/models/project.interface";
import { Badge } from "../../../shared/models/badge.interface";
import { Comment, Like } from "../../../shared/models/comun.interface";
import { User } from "../../../shared/models/user.interface";

import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "item-badge",
	templateUrl: "./item-badge.component.html",
	styleUrls: ["./item-badge.component.scss"],
})
export class ItemBadgeComponent implements OnInit {
	@Input("id_badge") idBadge: number;
	@Input("datos") badge: Badge;
	@Input("user") user: User;
	@Input("canEdit") canEdit: any;
	canEditComments;
	@Input("canSee") canSee: any;

	canSeeComments;

	form: UntypedFormGroup;
	showFormComent: boolean;

	projectId: number;
	likes: Array<Like>;
	tooltipLikes: string;
	comments: Array<Comment>;
	project: Project;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private badgeSvc: BadgesService,
		private userSvc: UserService,
		private translate: TranslateService
	) {
		this.comments = [];
		this.likes = [];
		this.tooltipLikes = "";
	}

	ngOnInit(): void {
		this.canEditComments = this.canEdit;
		this.canSeeComments = this.canSee;
		this.form = this._formBuilder.group({
			comentario: [""],
		});

		this.showFormComent = false;

		this.projectId = parseInt(this.projectSvc.getProjectId());
		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
			},
			(err) => {}
		);

		moment.locale(this.userSvc.getLanguageUser());
		this.loadLikesComments();
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	getImageGroup() {
		if (this.project.id == 10) {
			return "https://cdn.pixabay.com/photo/2016/04/15/18/05/computer-1331579_960_720.png";
		} else {
			return this.imagesSvc.getImageGroup();
		}
	}

	textTipoCompetencia(typeCompetency: number) {
		let text = "";

		if (typeCompetency === 0) {
			text = this.translate.instant("badge_editor.text.value");
		} else if (typeCompetency === 1) {
			text = this.translate.instant("badge_editor.text.mission");
		} else if (typeCompetency === 2) {
			text = this.translate.instant("badge_editor.text.company_mission");
		}

		return text;
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const badgeDate = moment(timestamp);
		const diffDias = hoy.diff(badgeDate, "days");

		let momentDate = badgeDate.fromNow();
		if (diffDias > 21) {
			momentDate = badgeDate.format("DD MMMM YYYY");
		}

		return momentDate;
	}
	momentDateFormat(timestamp: number) {
		// console.log("fecha", moment(timestamp));
		return moment(timestamp).format("DD/MM/YYYY HH:mm");
	}

	toogleLikeBadge() {
		this.badgeSvc.toogleLike(this.badge.id).subscribe(
			(data) => {
				if (this.badge.isLiked) {
					this.badge.numLikes--;
					this.badge.isLiked = false;
				} else {
					this.badge.numLikes++;
					this.badge.isLiked = true;
				}
				this.loadLikes();
			},
			(error) => {
				this.snackBar.open(error, "X", {
					duration: 3000,
				});
			}
		);
	}

	toogleFormComment(idBadge: number) {
		if (this.showFormComent) {
			this.showFormComent = false;
		} else {
			this.showFormComent = true;
		}
	}

	loadLikesComments(): void {
		if (this.badge.numComments > 0) {
			this.loadComments();
		}
		if (this.badge.numLikes > 0) {
			this.loadLikes();
		}
	}

	loadLikes(): void {
		this.badgeSvc.badgeLikes(this.badge.id).subscribe((data: any) => {
			// console.log("likes badges", data);
			this.likes = data.elements;
			this.tooltipLikes = "";
			for (const like of this.likes) {
				if (this.tooltipLikes !== "") {
					this.tooltipLikes += "\n";
				}
				this.tooltipLikes += like.autor.name;
			}
		});
	}

	loadComments(): void {
		this.badgeSvc.badgeComments(this.badge.id).subscribe((data: any) => {
			// console.log("coments badge", data);
			this.comments = data.elements;
		});
	}

	getBadgeImage(id) {
		return "https://wstwo.feedbalia.com/rest/image/10/competency/" + id;
	}
}
