<div fxLayout="row" class="item-badge is-timeline" fxLayoutAlign="center center">
	<div class="pr-5" fxLayout="row" fxLayoutAlign="center flex-start" *ngIf="project">
		<img
			*ngIf="badge.competency.typeCompetency === 0"
			class="img-competency-timeline"
			src="{{ imagesSvc.getUrlImageCompetency(badge.competency) }}"
			[title]="badge.competency.name"
		/>
		<img
			*ngIf="badge.competency.typeCompetency !== 0"
			class="img-competency-timeline"
			src="{{ imagesSvc.getUrlImageDefaultMission() }}"
			[title]="badge.competency.name"
		/>
		<div class="icon-line"></div>
	</div>

	<div class="person-avatar pr-16" fxLayout="row" fxLayoutAlign="center flex-start">
		<img
			*ngIf="project && project.entryPoint.showTimelineGrantor"
			class="img-circle"
			[src]="getImagePerson(badge.userDeliver.username)"
			[title]="badge.userDeliver.username"
		/>
		<img
			*ngIf="project && !project.entryPoint.showTimelineGrantor && badge.userReceive"
			class="img-circle"
			[src]="getImagePerson(badge.userReceive.username)"
			[title]="badge.userReceive.username"
		/>
		<img
			*ngIf="project && !project.entryPoint.showTimelineGrantor && badge.grantees.length > 0 && !badge.userReceive"
			[src]="getImageGroup()"
			class="img-circle"
		/>
		<!-- <div *ngIf="project && (!project.entryPoint.showTimelineGrantor) && badge.grantees.length>0 && !badge.userReceive" class="img-circle" style="width: 50px; text-align: center;">
            <mat-icon>{{ getImageGroup() }}</mat-icon>
        </div> -->
	</div>

	<div fxFlex="1 1 0">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="">
			<p class="m-0 badge-text">
				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 1">
					{{ "home.badges.from" | translate }}
					<a
						*ngIf="badge.userDeliver && user"
						[routerLink]="badge.userDeliver.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userDeliver.username]"
						style="cursor: pointer"
						>{{ badge.userDeliver.name }}</a
					>
					{{ "home.badges.to" | translate }}
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 2">
					<a
						*ngIf="badge.userDeliver && user"
						[routerLink]="badge.userDeliver.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userDeliver.username]"
						style="cursor: pointer"
						>{{ badge.userDeliver.name }}</a
					>
					{{ "general.ha_reconocido_a" | translate }}
				</ng-container>

				<!-- Receptor/es de la badge -->
				<ng-container *ngIf="badge.userReceive && user"
					><a [routerLink]="badge.userReceive.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userReceive.username]" style="cursor: pointer">{{
						badge.userReceive.name
					}}</a>
				</ng-container>

				<ng-container *ngIf="badge.grantees && badge.grantees.length > 0 && badge.grantees.length <= 10 && !badge.userReceive">
					<ng-container *ngFor="let grantee of badge.grantees; let i = index">
						<span *ngIf="i > 0 && i < badge.grantees.length - 1">, </span>
						<span *ngIf="i == badge.grantees.length - 1"> y </span>
						<a [routerLink]="grantee.id === user.id ? ['/perfil'] : ['/perfil/@' + grantee.username]" style="cursor: pointer">{{ grantee.name }}</a>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="badge.grantees && badge.grantees.length > 0 && badge.grantees.length > 10 && !badge.userReceive">
					<b>{{ badge.grantees.length }} {{ "timeline.users" | translate }}</b>
				</ng-container>
				<!-- ------------------- -->

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 1">
					{{ "home.badges.for" | translate }} {{ badge.competency.name }}.
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 2">
					{{ "general.in" | translate }} {{ textTipoCompetencia(badge.competency.typeCompetency) }}.
				</ng-container>

				<ng-container *ngIf="project && project.entryPoint.showCompetencyTextTimeline == 3">
					ha recibido la medalla <b>{{ badge.competency.name }}</b> de
					<a
						*ngIf="badge.userDeliver && user"
						[routerLink]="badge.userDeliver.id === user.id ? ['/perfil'] : ['/perfil/@' + badge.userDeliver.username]"
						style="cursor: pointer"
						>{{ badge.userDeliver.name }}</a
					>
				</ng-container>
				<ng-container *ngIf="project && project.enabled_subject && badge.subject != null && badge.subject != ''">
					<p>{{ "general.for" | translate }} {{ badge.subject }}</p>
				</ng-container>
			</p>
			<p class="badge-date m-0 ml-8" [title]="momentDateFormat(badge.fecha)">
				{{ momentDate(badge.fecha) }}
			</p>
		</div>
	</div>
</div>
