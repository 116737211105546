import { NgModule } from "@angular/core";
// import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";

import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";

import { FuseSharedModule } from "@fuse/shared.module";

import { CodigoSeguridadComponent } from "./codigo-seguridad.component";

const routes = [
	{
		path: "codigo-seguridad",
		component: CodigoSeguridadComponent,
	},
];

@NgModule({
	declarations: [CodigoSeguridadComponent],
	imports: [
		RouterModule.forChild(routes),

		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatTooltipModule,

		FuseSharedModule,
	],
})
export class CodigoSeguridadModule {}
