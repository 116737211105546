import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
	{
		id: "inicio",
		title: "Inicio",
		translate: "navbar.menu",
		type: "item",
		icon: "home",
		url: "/inicio",
	},
	/*
            {
                id       : 'perfil',
                title    : 'Perfil',
                translate: 'navbar.home',
                type     : 'item',
                icon     : 'person',
                url      : '/perfil',
            },
            {
                id       : 'control-horario',
                title    : 'Control horario',
                translate: 'navbar.controlhorario',
                type     : 'item',
                icon     : 'watch_later',
                url      : '/control-horario',
            },
            {
                id       : 'formaciones',
                title    : 'Formaciones',
                translate: 'navbar.formaciones',
                type     : 'item',
                icon     : 'school',
                url      : '/formaciones',
            },
            {
                id       : 'darBadge',
                title    : 'Dar agradecimiento',
                translate: 'navbar.badge',
                type     : 'item',
                icon     : 'favorite_border',
                function : 'darBadgeOpenDialog'
            },
            {
                id       : 'sied',
                title    : 'SIED',
                translate: 'navbar.sied',
                type     : 'item',
                icon     : 'assignment_turned_in',
                url      : '/sied'
            },
            {
                id       : 'expenses',
                title    : 'Gastos',
                translate: 'navbar.expenses',
                type     : 'collapsable',
                icon     : 'credit_card',
                children : [
                    {
                        id       : 'expenses',
                        title    : 'Mis gastos',
                        translate: 'navbar.expenses.me',
                        type     : 'item',
                        icon     : 'credit_card',
                        url      : '/expenses/me'
                    },
                    {
                        id       : 'expenses_team',
                        title    : 'Gastos de mi equipo',
                        translate: 'navbar.expenses.team',
                        type     : 'item',
                        hidden   : true,
                        icon     : 'credit_card',
                        url      : '/expenses/team'
                    }
                ]
            },
            {
                id       : 'publicaciones',
                title    : 'Publicaciones',
                translate: 'navbar.posts',
                type     : 'item',
                icon     : 'public',
                url      : '/publicaciones'
            },
            {
                id       : 'rankings',
                title    : 'Rankings',
                translate: 'navbar.rankings',
                type     : 'item',
                icon     : 'equalizer',
                url      : '/rankings',
            },
            {
                id       : 'shop',
                title    : 'Shop',
                translate: 'navbar.shop',
                type     : 'item',
                icon     : 'shopping_cart',
                url      : '/shop'
            },
            // {
            //     id       : 'chat',
            //     title    : 'Chat',
            //     translate: 'navbar.chat',
            //     type     : 'item',
            //     icon     : 'forum',
            //     url      : '/chat'
            // },
            {
                id       : 'vuelta-d',
                title    : 'Vuelta D',
                translate: 'Vuelta D',
                type     : 'item',
                icon     : 'help',
                url      : '/vuelta-d'
            },
            {
                id       : 'help',
                title    : 'Help',
                translate: 'navbar.help',
                type     : 'item',
                icon     : 'help_outline',
                url      : '/help'
            }
*/
];
