import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { UserService } from "../../services/user/user.service";
import { ProjectService } from "app/services/project/project.service";
import { Router } from "@angular/router";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ImagesService } from "app/services/helper/images.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import html2canvas from "html2canvas";
import { Location } from "@angular/common";

@Component({
	selector: "app-dialog-incidencia",
	templateUrl: "./dialog-incidencia.component.html",
	styleUrls: ["./dialog-incidencia.component.scss"],
})
export class DialogIncidenciaComponent implements OnInit {
	step = 1.1;
	shortElegida = null;
	formulario: UntypedFormGroup;
	uploadForm: UntypedFormGroup;

	selectedFile: File;
	selectedFileName: string;
	imagenes = "";
	enviando = false;
	showIt = true;
	captura = null;
	urlActual = "";
	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private userSvc: UserService,
		private imagesSvc: ImagesService,
		private snackBar: MatSnackBar,
		private location: Location,
		private dialogRef: MatDialogRef<DialogIncidenciaComponent>, // Inyecta MatDialogRef
	) {}

	ngOnInit(): void {
		this.urlActual = this.location.path();

		this.formulario = this.formBuilder.group({
			asunto: ["", [Validators.required, this.thisValueLengthValidator(80)]],
			cuerpo: ["", [Validators.required, this.thisValueLengthValidator(1024)]],
		});
		this.uploadForm = this.formBuilder.group({
			fileName: ["Captura", Validators.required],
			file: ["", this.fileValidator],
		});
	}

	chunk(arr: any[], size: number): any[][] {
		const chunkedArr = [];
		let index = 0;

		while (index < arr.length) {
			chunkedArr.push(arr.slice(index, size + index));
			index += size;
		}

		return chunkedArr;
	}

	fileValidator(control: AbstractControl) {
		const file = control.value;
		return file ? null : { required: true };
	}

	onFileSelected(files: FileList) {
		if (files.length > 0) {
			this.selectedFile = files[0];
			this.selectedFileName = files[0].name;
			this.uploadForm.controls.file.setValue(files[0]);
			this.uploadForm.controls.file.markAsTouched();
		}
	}
	thisValueLengthValidator(limite: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const thisvalue = control.value;
			if (thisvalue && thisvalue.length > limite) {
				return { thisValueTooLong: true };
			}
			return null;
		};
	}

	selectOne(short: string, asunto: string) {
		this.step = 1;
		this.shortElegida = short;
		this.formulario.patchValue({
			asunto: asunto,
		});
		console.log(this.formulario.value.asunto);
	}
	selectOtraIncidencia() {
		this.step = 1.1;
	}

	sendIt() {
		//console.log(this,this.uploadForm)
		console.log(this, this.formulario);
		this.enviando = true;

		this.userSvc
			.enviarIncidenciaSimple(this.formulario.value.asunto, this.formulario.value.cuerpo, this.captura, this.urlActual)
			.subscribe((data) => {
				this.dialogRef.close();
				this.showToast("Su incidencia ha sido enviada. Será revisada con la mayor brevedad posible. Muchas gracias.", 4000);
			});

		setTimeout(() => {
			this.enviando = false;
		}, 5000);
	}

	backStepArchivo(step) {
		this.step = step;
		this.uploadForm = this.formBuilder.group({
			fileName: ["", Validators.required],
			file: ["", this.fileValidator],
		});
		this.selectedFile = null;
		this.selectedFileName = null;
	}

	backStepForm(step) {
		this.step = step;
		this.formulario = this.formBuilder.group({
			asunto: ["", [Validators.required, this.thisValueLengthValidator(80)]],
			cuerpo: ["", [Validators.required, this.thisValueLengthValidator(1024)]],
		});
	}

	private showToast(toast, duration) {
		this.snackBar.open(toast, "X", {
			duration: duration,
			verticalPosition: "top",
		});
	}
	captureScreen() {
		//this.ocultarPor3Segundos();
		const dialogContainer = document.querySelector(".cdk-overlay-container") as HTMLDivElement;
		dialogContainer.style.display = "none"; // Aplica la clase CSS para ocultar el diálogo

		const options = {
			scale: 1, // Ajusta la escala para mejorar la calidad (puedes ajustar este valor según sea necesario)
			//	  windowHeight: window.innerHeight, // Usa la altura de la ventana como altura de la captura
			//  windowWidth: window.innerWidth// Usa el ancho de la ventana como ancho de la captura
		};

		html2canvas(document.body, options).then((canvas) => {
			// `canvas` contiene la captura de pantalla como un elemento HTMLCanvasElement
			// Abre una nueva ventana o pestaña con la imagen capturada
			const imgData = canvas.toDataURL("image/png");
			this.captura = imgData;
			//  const newWindow = window.open();
			// newWindow.document.write('<img src="' + imgData + '" width="max" height="max">');
		});
		dialogContainer.style.display = "block"; // Aplica la clase CSS para ocultar el diálogo

		this.showIt = true;
	}
}
