<div id="security-code" fxLayout="column">
	<div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
		<div id="securitycode-form" [@animate]="{ value: '*', params: { duration: '800ms', y: '500px' } }">
			<div class="logo">
				<img *ngIf="entryPointResources !== ''" [src]="imageSvc.getLogo()" />
				<img *ngIf="entryPointResources === ''" src="assets/images/logos/logo.png" />
			</div>

			<div>
				<h3>{{ "codigo_seguridad.title" | translate }}</h3>
			</div>

			<form name="securityCodeForm" [formGroup]="securityCodeForm" (ngSubmit)="verifyCode()" novalidate>
				<mat-form-field subscriptSizing="dynamic" appearance="outline">
					<mat-label>{{ "codigo_seguridad.code" | translate }}</mat-label>
					<input matInput formControlName="securityCode" />
					<mat-icon matSuffix class="secondary-text">lock</mat-icon>
					<mat-error *ngIf="securityCodeForm.get('securityCode').hasError('required')">
						{{ "codigo_seguridad.code_input" | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-raised-button color="accent" class="submit-button w-100-p" aria-label="Entrar">
					{{ "general.button.accept" | translate }}
				</button>
			</form>

			<div fxLayout="row" fxLayoutAlign="center center" class="h-10" style="position: relative">
				<mat-error *ngIf="msgError && msgError !== ''" style="position: absolute; top: 2px">{{ msgError
					}}</mat-error>
			</div>
		</div>
	</div>
</div>