import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { TranslateLoader } from "@ngx-translate/core";

import { ProjectService } from "./project/project.service";

import { environment } from "../../environments/environment";

// @Injectable({
//     providedIn: 'root'
// })
export class CustomTranslateLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private injector: Injector,
	) {}

	getTranslation(lang: string): Observable<any> {
		// console.log("GET TRANSLATION", lang);
		const projectInj = this.injector.get(ProjectService);
		const projectId = projectInj.getProjectId();

		return new Observable<any>((observer) => {
			const options = {
				headers: new HttpHeaders({
					Authentication: "Basic " + environment.clientSecret,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			this.http.get(`${environment.urlDomain}/api/v1/language/${lang}?project=${projectId}`, options).subscribe(
				(res) => {
					//console.log("LANGUAJE FILE WS", res);
					observer.next(res);
					observer.complete();
				},
				(error) => {
					this.http.get("/assets/i18n/" + lang + ".json").subscribe((res: Response) => {
						// console.log("LANGUAGE FILE", res);
						observer.next(res);
						observer.complete();
					});
				},
			);
		});
	}
}
