import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

import { FuseSharedModule } from "@fuse/shared.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { BadgeBarComponent } from "app/layout/components/badge-bar/badge-bar.component";
import { BadgeBarItemComponent } from "./badge-bar-item/badge-bar-item.component";

@NgModule({
	declarations: [BadgeBarComponent, BadgeBarItemComponent],
	imports: [RouterModule, MatButtonModule, MatIconModule, MatTooltipModule, FuseSharedModule, FontAwesomeModule, InfiniteScrollModule],
	exports: [BadgeBarComponent],
})
export class BadgeBarModule {}
