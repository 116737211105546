<div class="badgebar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
	<span class="logo-text" fxHide.lt-lg>Reconocimientos</span>

	<button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
		<mat-icon>menu</mat-icon>
	</button>

	<!-- <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button> -->
</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
	<div
		class="fuse-card list-agradecimientos"
		infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		[scrollWindow]="false"
		(scrolled)="onSrollTimeline()"
	>
		<div *ngFor="let itemBadge of badgesTimeline">
			<badge-bar-item [user]="user" [id_badge]="itemBadge.id" [datos]="itemBadge"></badge-bar-item>
		</div>
	</div>
</div>
