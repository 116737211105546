<div mat-dialog-title fxLayout="column" fxLayoutAlign="space-between flex-start">
	<div fxLayout="row" fxLayoutAlign="space-between top" class="fullwidth">
		<div>
			<p>Modo de solo lectura</p>
		</div>

		<button mat-icon-button mat-dialog-close style="color: #666">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="fullwidth" style="position: relative; margin-top: 5px">
		<mat-form-field subscriptSizing="dynamic" class="search-user w-100-p">
			<input matInput placeholder="Selecciona la persona a suplantar" [(ngModel)]="filterPersonValue"
				(keyup)="filterPersons($event)" />
			<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
			<button mat-button *ngIf="filterPersonValue.length > 0" matSuffix mat-icon-button aria-label="Clear"
				(click)="clearPerson()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>

		<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
			<div class="list-persons">
				<div *ngFor="let person of persons" fxlayout="row" fxLayoutAlign="flex-start center" class="item-person"
					(click)="selectPerson(person)">
					<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
						<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
					</div>
					<div fxLayout="column" class="info-person">
						<span class="m-0 font-size-14">{{ "@" + person.username }}</span>
						<span class="m-0 font-size-14"><strong>{{ person.displayName }}</strong></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>