import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ProjectService } from "../project/project.service";
import { UserService } from "../user/user.service";
import { PersonService } from "../person/person.service";

@Injectable({
	providedIn: "root",
})
export class isBossGuard {
	projectId: number;
	project: any;

	constructor(
		public router: Router,
		private personSvc: PersonService,
		private projectSvc: ProjectService,
		private userSvc: UserService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		try {
			let id_check = route.params.id;
			let array = JSON.parse(localStorage.getItem("bossOf"));
			if (array.includes(id_check)) {
				return true;
			} else {
				this.router.navigate(["/liquidaciones/fichas"]);
				return false;
			}
		} catch {
			this.router.navigate(["/liquidaciones/fichas"]);
			return false;
		}
	}
}
