import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { fuseAnimations } from "@fuse/animations";
import { FuseConfigService } from "@fuse/services/config.service";

import { AuthService } from "app/services/auth/auth.service";
import { ImagesService } from "app/services/helper/images.service";
import moment from "moment";
import { ProjectService } from "../../services/project/project.service";
import { UserService } from "../../services/user/user.service";

@Component({
	selector: "codigo-seguridad",
	templateUrl: "./codigo-seguridad.component.html",
	styleUrls: ["./codigo-seguridad.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class CodigoSeguridadComponent implements OnInit {
	securityCodeForm: UntypedFormGroup;
	msgError: string;
	personalDataConfirmation: boolean;

	returnUrl: string;
	accessData: any;
	user: any;
	entryPointResources: String;
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private authSvc: AuthService,
		private userSvc: UserService,
		private router: Router,
		private projectService: ProjectService,
		private route: ActivatedRoute,
		private imageSvc: ImagesService,
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};

		this.accessData = this.router.getCurrentNavigation().extras.state.accessData;
	}

	ngOnInit(): void {
		this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/inicio";

		this.securityCodeForm = this._formBuilder.group({
			securityCode: ["", [Validators.required]],
		});
		this.msgError = "";
		this.ckeckIsProjectActive();
		this.entryPointResources = this.projectService.getEntryPointResources();
	}

	ckeckIsProjectActive() {
		this.projectService.getProjectConfig().subscribe(
			(data) => {
				let json = JSON.parse(JSON.stringify(data));

				this.personalDataConfirmation = json.personalDataConfirmation;
			},
			(error) => {
				console.log(error);
			},
		);
	}

	verifyCode(): void {
		this.msgError = "";
		const formValue = this.securityCodeForm.value;

		if (this.securityCodeForm.valid) {
			this.userSvc.verifySecurityCode(formValue.securityCode).subscribe(
				(data) => {
					this.saveToken(this.accessData);
					this.userSvc.getDataUser().subscribe((data) => {
						this.user = data;

						if (this.personalDataConfirmation == false || this.user["personalDataValid"] == 1) {
							this.router.navigate([this.returnUrl], { replaceUrl: true });
						} else {
							this.router.navigate(["/complete-data"], { replaceUrl: true });
						}
					});
				},
				(err) => {
					this.msgError = err;
				},
			);
		}
	}

	private saveToken(token): void {
		token = JSON.parse(token);

		let now = moment();
		let dateExpired = now.toDate().getTime() + token.expires_in;
		token.dateExpired = dateExpired;

		const tokenString = JSON.stringify(token);
		localStorage.setItem("tokenUser", tokenString);
	}
}
