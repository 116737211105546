import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "badges-mes",
	templateUrl: "./badges-mes.component.html",
	styleUrls: ["./badges-mes.component.scss"],
})
export class BadgesMesComponent implements OnInit {
	@Input("tipo") tipo: string;
	@Input("num_badges") numBadges: number;

	constructor() {}

	ngOnInit(): void {}
}
