<mat-toolbar class="p-0 mat-elevation-z1">
	<div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
		<div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
			<button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
				<mat-icon class="secondary-text">menu</mat-icon>
			</button>

			<div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

			<div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
				<div class="logo ml-16">
					<img class="logo-icon" src="assets/images/logos/fuse.svg" />
				</div>
			</div>
			<!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
		</div>

		<div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
			<button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
				<div fxLayout="row" fxLayoutAlign="center center">
					<img *ngIf="person" class="avatar mr-0 mr-sm-16 border-white" [src]="getImageUser(person)" />
					<!-- <span class="username mr-12" fxHide fxShow.gt-sm>Charlie Adams</span> -->
					<mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
				</div>
			</button>

			<!-- Profile -->
			<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
				<ng-container *ngIf="entorno == 'ADMIN'">
					<button mat-menu-item routerLink="/inicio" class="">
						<mat-icon>arrow_back</mat-icon>
						<span>Web</span>
					</button>
				</ng-container>

				<ng-container *ngIf="entorno != 'ADMIN'">
					<button mat-menu-item routerLink="/perfil">
						<mat-icon>account_circle</mat-icon>
						<span>{{ "navbar.home" | translate }}</span>
					</button>

					@if(projectId!= 472 && permisosConfig.w){
					<button mat-menu-item routerLink="/account" class="">
						<mat-icon>settings</mat-icon>
						<span>{{ "navbar.user.account" | translate }}</span>
					</button>
					}

					<button *ngIf="readOnlyMode" mat-menu-item (click)="openReadOnlyModal()" class="">
						<mat-icon>supervisor_account</mat-icon>
						<span>{{ "navbar.user.readOnlyMode" | translate }}</span>
					</button>

					<!-- *ngIf="urlAdmin" -->
					@if(canEnterNewAdmin){ @if(newAdminPermisos){
					<button mat-menu-item routerLink="/administracion/usuarios">
						<mat-icon>dashboard</mat-icon>
						<span>{{ "navbar.administracion" | translate }}</span>
					</button>
					} }@else { @if(urlAdmin){
					<button mat-menu-item (click)="navigateTo(urlAdmin)" class="">
						<mat-icon>dashboard</mat-icon>
						<span>{{ "navbar.user.dashboard" | translate }}</span>
					</button>
					} }
				</ng-container>

				<button mat-menu-item (click)="cerrarSesion()" class="">
					<mat-icon>power_settings_new</mat-icon>
					<span>{{ "navbar.user.close_session" | translate }}</span>
				</button>
			</mat-menu>

			<ng-container *ngIf="entorno == 'WEB'">
				<div class="toolbar-separator"></div>

				<!-- Search bar -->
				<fuse-search-bar (input)="search($event)" (close)="closeSearch()"></fuse-search-bar>
				<div *ngIf="persons.length > 0" class="wrap-persons">
					<div class="list-persons">
						<div *ngFor="let person of persons" fxlayout="row" fxLayoutAlign="flex-start center" class="item-person" (click)="selectPerson(person)">
							<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
								<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
							</div>
							<div fxLayout="column" class="info-person">
								<span class="m-0 font-size-14">{{ "@" + person.username }}</span>
								<span class="m-0 font-size-14"
									><strong>{{ person.displayName }}</strong></span
								>
							</div>
						</div>
					</div>
				</div>

				<!-- FUNESPAña soporte con triaje -->
				<ng-container *ngIf="projectId == 472">
					<!-- notificationsFormaciones -->
					<div class="toolbar-separator"></div>
					<div>
						<button mat-icon-button (click)="openHelpDialog()" class="quick-panel-toggle-button" aria-label="Toggle dialog">
							<mat-icon class="secondary-text">headset_mic</mat-icon>
						</button>
					</div>
				</ng-container>

				<ng-container *ngIf="medalsShortcutToolbarEnabled">
					<div class="toolbar-separator"></div>

					<!-- Medals -->
					<div>
						<button mat-icon-button class="quick-panel-toggle-button" (click)="darBadgeOpenDialog()">
							<mat-icon class="secondary-text">
								<fa-icon icon="medal"></fa-icon>
							</mat-icon>
						</button>
					</div>
				</ng-container>

				<div class="toolbar-separator"></div>

				<!-- Notifications -->
				<div *ngIf="notifications" class="notificacionesMenu">
					<button mat-icon-button [matMenuTriggerFor]="notificationsMenu" class="quick-panel-toggle-button" aria-label="Toggle quick panel">
						<mat-icon class="secondary-text">notifications</mat-icon>
						<div *ngIf="notifications.totalElements" class="notifications">
							{{ notifications.totalElements }}
						</div>
					</button>

					<mat-menu #notificationsMenu="matMenu" [overlapTrigger]="false" style="max-width: none !important">
						<div
							class="notifications-container"
							infiniteScroll
							[infiniteScrollDistance]="1"
							[infiniteScrollThrottle]="50"
							[scrollWindow]="false"
							(scrolled)="onSrollNotifications()"
						>
							<button *ngIf="notifications.elements.length && projectId != 472" mat-raised-button class="read-button" (click)="markAllAsReaded()">
								Marcar todo como leido
							</button>
							<button *ngIf="!notifications.elements.length" mat-menu-item>
								<span>No tienes nuevas notificaciones.</span>
							</button>

							<button
								*ngFor="let notification of notifications.elements"
								(click)="openNotification(notification)"
								mat-menu-item
								[title]="notification.message"
								style="margin-bottom: 4px"
							>
								<mat-icon>{{ getNotificationIcon(notification.modelName) }}</mat-icon>
								<span style="font-size: 14px">{{ notification.message || notification.title }}</span>
							</button>
						</div>
					</mat-menu>
				</div>

				<div class="toolbar-separator"></div>

				<ng-container *ngIf="projectId == 10">
					<!-- notificationsFormaciones -->
					<div *ngIf="notificationsFormaciones">
						<button mat-icon-button [matMenuTriggerFor]="notificationsMenu" class="quick-panel-toggle-button" aria-label="Toggle quick panel">
							<mat-icon class="secondary-text">school</mat-icon>
							<div *ngIf="notificationsFormaciones.totalElements" class="notifications">
								{{ notificationsFormaciones.totalElements }}
							</div>
						</button>
						<mat-menu #notificationsMenu="matMenu" [overlapTrigger]="false">
							<div
								class="notifications-container"
								infiniteScroll
								[infiniteScrollDistance]="1"
								[infiniteScrollThrottle]="50"
								[scrollWindow]="false"
								(scrolled)="onSrollNotificationsFormaciones()"
							>
								<button *ngIf="notificationsFormaciones.elements.length" mat-raised-button class="read-button" (click)="markAllAsReadedFormaciones()">
									Marcar todo como leido
								</button>
								<button *ngIf="!notificationsFormaciones.elements.length" mat-menu-item>
									<span>No notificaciones sobre formaciones nuevas.</span>
								</button>
								<button
									*ngFor="let notification of notificationsFormaciones.elements"
									(click)="openNotification(notification)"
									mat-menu-item
									[title]="notification.message"
									[ngClass]="{ 'bg-readed': notification.readed == true }"
									style="margin-bottom: 4px"
								>
									<mat-icon [ngClass]="{ 'red-badge': notification.readed == false }">{{ getNotificationIcon(notification.modelName) }}</mat-icon>
									<span style="font-size: 14px">{{ notification.message || notification.title }}</span>
								</button>
							</div>
						</mat-menu>
					</div>
					<div class="toolbar-separator"></div>
				</ng-container>

				<!-- Language -->
				<button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
					<div *ngIf="selectedLanguage" fxLayout="row" fxLayoutAlign="center center">
						<img class="flag mr-8" [src]="'assets/icons/flags/' + selectedLanguage.flag + '.png'" />
						<span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
					</div>
				</button>
				<mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
					<button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
						<span fxLayout="row" fxLayoutAlign="start center">
							<img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
							<span class="iso">{{ lang.title | translate }}</span>
						</span>
					</button>
				</mat-menu>

				<div class="toolbar-separator" fxHide fxShow.gt-xs></div>
			</ng-container>

			<!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->
		</div>
	</div>
</mat-toolbar>
