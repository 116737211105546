import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { UserService } from "../user/user.service";

@Injectable({
	providedIn: "root",
})
export class MustChangePasswordGuardService {
	constructor(
		public router: Router,
		public auth: AuthService,
		private userSvc: UserService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		// console.log("mustChangePassword canActivate");
		return this.userSvc.checkMustChangePassword();
	}
}
