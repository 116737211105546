import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { ProjectService } from "app/services/project/project.service";
import { AlertError } from "../error-handler";

declare var gtag: Function;

@Injectable({
	providedIn: "root",
})
export class AnalyticsService {
	constructor(
		private router: Router,
		private projectSvc: ProjectService,
	) {}

	public event(eventName: string, params: {}) {
		gtag("event", eventName, params);
	}

	public init() {
		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				if (data.entryPoint.googleAnalytics && data.entryPoint.googleAnalytics !== (null || "" || " ")) {
					this.listenForRouteChanges(data.entryPoint.googleAnalytics);

					try {
						const script1 = document.createElement("script");
						script1.async = true;
						script1.src = "https://www.googletagmanager.com/gtag/js?id=" + data.entryPoint.googleAnalytics;
						document.head.appendChild(script1);

						const script2 = document.createElement("script");
						script2.innerHTML =
							`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '` +
							data.entryPoint.googleAnalytics +
							`', {'send_page_view': false});
                  `;
						document.head.appendChild(script2);
					} catch (ex) {
						console.error("Error appending google analytics");
						console.error(ex);
					}
				} else {
					// console.log("No google analytics");
				}
			},
			(err) => {
				AlertError.showError(err);
			},
		);
	}

	private listenForRouteChanges(googleAnalyticsKey) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				gtag("config", googleAnalyticsKey, {
					page_path: event.urlAfterRedirects,
				});
				console.log("Sending Google Analytics hit for route", event.urlAfterRedirects);
				console.log("Property ID", googleAnalyticsKey);
			}
		});
	}
}
