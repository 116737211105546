import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	private countRequest = 0;
	constructor(public _progressBar: FuseProgressBarService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.countRequest) {
			this._progressBar.show();
		}
		this.countRequest++;
		return next.handle(request).pipe(
			finalize(() => {
				this.countRequest--;
				if (!this.countRequest) {
					this._progressBar.hide();
				}
			}),
		);
	}
}
