import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

//IDLE
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { ProjectService } from "app/services/project/project.service";
import { RainbowService } from "app/services/rainbow/rainbow.service";

import { navigation } from "app/navigation/navigation";

import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "./services/auth/auth.service";
import { AlertError } from "./services/error-handler";

@Component({
	selector: "app",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;

	//IDLE
	idleState = "Not started.";
	timedOut = false;
	timedOutWarning = false;
	lastPing?: Date = null;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseNavigationService} _fuseNavigationService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {FuseSplashScreenService} _fuseSplashScreenService
	 * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
	 * @param {Platform} _platform
	 * @param {TranslateService} _translateService
	 * @param {RainbowService} _rbwService
	 * @param {ProjectService} _projectService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _fuseSplashScreenService: FuseSplashScreenService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private _translateService: TranslateService,
		private _projectService: ProjectService,
		private _rbwService: RainbowService,
		private _platform: Platform,

		private idle: Idle,
		private keepalive: Keepalive,

		private authSvc: AuthService,
		private router: Router,
		private snackBar: MatSnackBar,
	) {
		// Get default navigation
		this.navigation = navigation;

		// Register the navigation to the service
		this._fuseNavigationService.register("main", this.navigation);

		// Set the main navigation as our current navigation
		this._fuseNavigationService.setCurrentNavigation("main");

		// Add languages
		// this._translateService.addLangs(['en', 'es']);

		// // Set the default language
		// this._translateService.setDefaultLang('es');

		// // Set the navigation translations
		// this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationSpanish);

		// // Use a language
		// this._translateService.use('es');

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix Start
		 * ----------------------------------------------------------------------------------------------------
		 */

		/**
		 * If you are using a language other than the default one, i.e. Turkish in this case,
		 * you may encounter an issue where some of the components are not actually being
		 * translated when your app first initialized.
		 *
		 * This is related to ngxTranslate module and below there is a temporary fix while we
		 * are moving the multi language implementation over to the Angular's core language
		 * service.
		 **/

		// Set the default language to 'en' and then back to 'tr'.
		// '.use' cannot be used here as ngxTranslate won't switch to a language that's already
		// been selected and there is no way to force it, so we overcome the issue by switching
		// the default language back and forth.
		/**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix End
		 * ----------------------------------------------------------------------------------------------------
		 */

		// Add is-mobile class to the body if the platform is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this.document.body.classList.add("is-mobile");
		}

		// Set the private defaults
		this._unsubscribeAll = new Subject();

		//IDLE
		idle.setIdle(900);
		idle.setTimeout(60);
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		//idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
		idle.onTimeout.subscribe(() => {
			//this.timedOut = true;
			if (this.authSvc.isUserLoggedIn()) {
				this.cerrarSesion();
				this.resetIdle();
			}
		});
		//idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
		idle.onTimeoutWarning.subscribe((countdown) => {
			if (this.authSvc.isUserLoggedIn()) {
				this.idleState = "La sesión expirará en " + countdown + " segundos debido a inactividad.";
				this.timedOutWarning = true;
				idle.clearInterrupts();
			}
		});

		this.resetIdle();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._projectService.getProjectDomain();

		// Subscribe to config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.fuseConfig = config;

			// Boxed
			if (this.fuseConfig.layout.width === "boxed") {
				this.document.body.classList.add("boxed");
			} else {
				this.document.body.classList.remove("boxed");
			}

			// Color theme - Use normal for loop for IE11 compatibility
			for (let i = 0; i < this.document.body.classList.length; i++) {
				const className = this.document.body.classList[i];

				if (className.startsWith("theme-")) {
					this.document.body.classList.remove(className);
				}
			}

			this.document.body.classList.add(this.fuseConfig.colorTheme);
		});

		this._rbwService.init();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	resetIdle() {
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		this.idle.watch();
		//this.timedOut = false;
		this.timedOutWarning = false;
	}

	ngAfterViewInit() {
		AlertError.setSnackBar(this.snackBar);
	}

	cerrarSesion() {
		this.authSvc.logout();

		if (this._projectService.projectConfig.enabled_cas_login) {
			window.location.href = "saml/logout";
		} else this.router.navigate(["login"], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
	}
}
