import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "./auth.service";
import { map, switchMap, tap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class AuthGuardService {
	constructor(
		public router: Router,
		public auth: AuthService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (!this.auth.isUserLoggedIn()) {
			this.router.navigate(["login"], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
			return false;
		} else if (state.url.includes("administracion")) {
			return this.auth.isAdmin().pipe(
				switchMap((isAdmin) => {
					if (!isAdmin) {
						this.router.navigate(["inicio"]);
						return of(false);
					}

					if (!state.url.includes("administracion/proyectos")) {
						return of(true);
					}

					return this.auth.isSuperAdmin().pipe(
						map((isSuperAdmin) => {
							if (!isSuperAdmin) {
								this.router.navigate(["administracion"]);
								return false;
							}
							return true;
						}),
					);
				}),
			);
		}

		return true;
	}
}
