import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class NotificationService {
	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
	) {}

	getNotificaciones(page, pageSize, readed?: number, type?: string) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};
			let readedParam = "";
			if (readed !== undefined) {
				readedParam = "&readed=" + readed;
			}
			let typeParam = "";
			if (type !== undefined) {
				typeParam = "&type=" + type;
			}
			const url = environment.urlDomain + "/api/v1/notification/" + "?page=" + page + "&pageSize=" + pageSize + "" + readedParam + typeParam;

			this.http.get(url, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	markNotificationAsRead(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/notification/" + id + "/markAsRead";

			this.http.put(url, {}, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	markAllNotificationsAsRead() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/notification/markAsRead";

			this.http.put(url, {}, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
	markAllNotificationsAsReadFormaciones() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/notification/markAsReadFormaciones";

			this.http.put(url, {}, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}
}
