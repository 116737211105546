import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { ProjectService } from "../project/project.service";
import { UserService } from "../user/user.service";

@Injectable()
export class CheckRolGuard {
	projectId: number;
	project: any;

	constructor(public router: Router, private userSvc: UserService, private projectSvc: ProjectService, private authSvc: AuthService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return new Promise<boolean>((resolve, reject) => {
			const pro = this.projectSvc.projectConfig;
			if (pro) {
				if (pro["rolesWeb"] == 1) {
					this.authSvc.getPermisosWeb("WEB-GRAFICAS").subscribe(
						(data) => {
							console.log("Permisos para esta pagina", data);
							if (data.r == 1) {
								resolve(true);
							} else {
								resolve(false);
							}
						},
						(error) => {
							this.router.navigate(["inicio"]);
							resolve(false);
						}
					);
				} else {
					this.userSvc.getDataUser().subscribe(
						(data) => {
							let have_rol_admin = false;
							for (let elem of data["roles"]) {
								if (elem.rol == "ROLE_ADMIN_APP") {
									have_rol_admin = true;
								}
								if (elem.rol == "ADMIN_MARCA") {
									have_rol_admin = true;
								}
								console.log(have_rol_admin);
							}
							if (!have_rol_admin) {
								this.router.navigate(["inicio"]);
								resolve(false);
							} else {
								resolve(true);
							}
						},
						(error) => {
							this.router.navigate(["inicio"]);
							resolve(false);
						}
					);
				}
			} else {
				resolve(false);
			}
		});
	}
}
