import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BadgesService } from "app/services/badges/badges.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { Badge } from "app/shared/models/badge.interface";
import { Project } from "app/shared/models/project.interface";
import { User } from "app/shared/models/user.interface";
import moment from "moment";

@Component({
	selector: "badge-bar-item",
	templateUrl: "./badge-bar-item.component.html",
	styleUrls: ["./badge-bar-item.component.scss"],
})
export class BadgeBarItemComponent implements OnInit {
	@Input("id_badge") idBadge: number;
	@Input("datos") badge: Badge;
	@Input("user") user: User;

	projectId: number;
	comments: Array<Comment>;
	project: Project;

	constructor(
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private badgeSvc: BadgesService,
		private userSvc: UserService,
		private translate: TranslateService,
	) {
		this.comments = [];
	}

	ngOnInit(): void {
		this.projectId = parseInt(this.projectSvc.getProjectId());
		this.projectSvc.getProjectConfig().subscribe((data) => {
			this.project = data;
		});

		moment.locale(this.userSvc.getLanguageUser());
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	getImageGroup() {
		return this.imagesSvc.getImageGroup();
	}

	textTipoCompetencia(typeCompetency: number) {
		let text = "";

		if (typeCompetency === 0) {
			text = this.translate.instant("badge_editor.text.value");
		} else if (typeCompetency === 1) {
			text = this.translate.instant("badge_editor.text.mission");
		} else if (typeCompetency === 2) {
			text = this.translate.instant("badge_editor.text.company_mission");
		}

		return text;
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const badgeDate = moment(timestamp);
		const diffDias = hoy.diff(badgeDate, "days");

		let momentDate = badgeDate.fromNow();
		if (diffDias > 21) {
			momentDate = badgeDate.format("DD-MM YYYY");
		}

		return momentDate;
	}
	momentDateFormat(timestamp: number) {
		// console.log("fecha", moment(timestamp));
		return moment(timestamp).format("DD/MM/YYYY HH:mm");
	}
}
